import React, { useEffect, useState } from "react";
import {
  addBankingDetails,
  getAuthUser,
  getBankingDetails,
  getUser,
  updateBankingDetails,
} from "../../firebase/firestore";
import Swal from "sweetalert2";
import "./style.css";

export default function BankingDetails() {
  const [formData, setFormData] = useState({
    accountName: "",
    bankName: "",
    branch: "",
    bsbNumber: 0,
    accountNumber: 0,
    iban: 0,
    swiftCode: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [userCountry, setUserCountry] = useState({
    country: "",
  });
  const country = userCountry.country.value;

  const fetchUserCountry = async () => {
    setIsLoading(true);
    const userUID = getAuthUser();
    if (!userUID) {
      console.log("No UID found.");
      return;
    }
    try {
      const usersData = await getUser(userUID);
      if (usersData.length > 0) {
        const userData = usersData[0];
        setUserCountry({
          ...userData,
          country: userData.country,
        });
      }
    } catch (error) {
      console.log("Error fetching user data: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchBankingDetails = async () => {
    setIsLoading(true);
    const user = getAuthUser();

    if (!user) {
      console.log("No user is currently authenticated.");
      return;
    }

    try {
      const data = await getBankingDetails(user);

      if (data.length > 0) {
        const bankingDetails = data[0];
        setFormData({
          accountName: bankingDetails.accountName,
          bankName: bankingDetails.bankName,
          branch: bankingDetails.branch,
          bsbNumber: bankingDetails.bsbNumber,
          accountNumber: bankingDetails.accountNumber,
          iban: bankingDetails.iban,
          swiftCode: bankingDetails.swiftCode,
        });
      }
    } catch (error) {
      console.error("Error fetching banking details: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBankingDetails();
    fetchUserCountry();
  }, []);

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const user = getAuthUser();

    if (!user) {
      console.log("No user is currently authenticated.");
      return;
    }

    const isValidBSBNumber = /^(?:\d{3}-\d{3}|\d{6})$/.test(formData.bsbNumber);
    const isValidAccountNumber = /^\d{6,10}$/.test(formData.accountNumber);
    const isValidIbanNumber = /^[A-Za-z]{2}\d{2}[A-Za-z0-9]{11,29}$/.test(
      formData.iban
    );
    const isValidSwiftCode =
      /^[A-Za-z]{4}[A-Za-z]{2}[A-Za-z0-9]{2}([A-Za-z0-9]{3})?$/.test(
        formData.swiftCode
      );

    if (!isValidBSBNumber) {
      setError("BSB number must be 6 digits long.");
      setTimeout(() => {
        setError("");
      }, 3000);
      setIsLoading(false);
      return;
    }
    if (!isValidAccountNumber) {
      setError("Account number must be between 6 and 10 digits long.");
      setTimeout(() => {
        setError("");
      }, 3000);
      setIsLoading(false);
      return;
    }
    if (!isValidIbanNumber) {
      setError(
        "Invalid IBAN format. IBAN should be between 15 to 34 alphanumeric characters, starting with a 2-letter country code followed by 2 digits, and then up to 30 alphanumeric characters."
      );
      setTimeout(() => {
        setError("");
      }, 3000);
      setIsLoading(false);
      return;
    }
    if (!isValidSwiftCode) {
      setError(
        "Invalid SWIFT code format. SWIFT code should be 8 or 11 characters long, consisting of a 4-letter bank code, 2-letter country code, 2-character location code, and an optional 3-character branch code."
      );
      setTimeout(() => {
        setError("");
      }, 3000);
      setIsLoading(false);
      return;
    }

    const bankingDetailsData = {
      accountName: formData.accountName,
      bankName: formData.bankName,
      branch: formData.branch,
      bsbNumber: formData.bsbNumber,
      accountNumber: formData.accountNumber,
      iban: formData.iban,
    };

    try {
      const userBankingDetails = await getBankingDetails(user);

      if (userBankingDetails && userBankingDetails.length > 0) {
        const docId = userBankingDetails[0].id; // Assuming the first document is the one to update
        await updateBankingDetails(user, docId, bankingDetailsData);
        Swal.fire({
          icon: "success",
          title: "Banking details updated!",
          text: `Banking details updated successfully!`,
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        await addBankingDetails(user, bankingDetailsData);
        Swal.fire({
          icon: "success",
          title: "Banking details updated!",
          text: `Banking details added successfully!`,
          showConfirmButton: false,
          timer: 2000,
        });
      }

      fetchBankingDetails(); // fetch details again after saving or adding
    } catch (error) {
      console.error("Error updating/adding banking details: ", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `Failed to update/add banking details. Please try again.`,
        showConfirmButton: false,
        timer: 2000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="banking_details">
      <div className="section_header">
        <h2 className="title">Banking Details</h2>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="180"
            height="9"
            viewBox="0 0 230 9"
          >
            <rect
              id="Rectangle_28"
              data-name="Rectangle 28"
              width="230"
              height="9"
              rx="4.5"
              fill="#688fb7"
            ></rect>
          </svg>
        </div>
      </div>

      <form action="" className="info_form" onSubmit={handleUpdate}>
        <div className="form_group">
          <div className="input_group">
            <label htmlFor="account_name">Account Holder's Name:</label>
            <input
              type="text"
              name="account_name"
              className="input_field"
              value={formData.accountName}
              onChange={(e) =>
                setFormData({ ...formData, accountName: e.target.value })
              }
            />
          </div>
        </div>
        <div className="form_group">
          <div className="input_group">
            <label htmlFor="bank_name">Bank Name:</label>
            <input
              type="text"
              name="bank_name"
              className="input_field"
              value={formData.bankName}
              onChange={(e) =>
                setFormData({ ...formData, bankName: e.target.value })
              }
            />
          </div>
          <div className="input_group">
            <label htmlFor="branch">Branch Address:</label>
            <input
              type="text"
              name="branch"
              className="input_field"
              value={formData.branch}
              onChange={(e) =>
                setFormData({ ...formData, branch: e.target.value })
              }
            />
          </div>
        </div>
        <div className="form_group">
          {country === "AU" &&
          <div className="input_group">
            <label htmlFor="bsbNumber">BSB Number:</label>
            <input
              type="text"
              name="bsbNumber"
              className="input_field"
              placeholder="6 digits"
              value={formData.bsbNumber}
              onChange={(e) =>
                setFormData({ ...formData, bsbNumber: e.target.value })
              }
            />
          </div>
          }
          <div className="input_group">
            <label htmlFor="accountNumber">Account Number:</label>
            <input
              type="text"
              name="accountNumber"
              placeholder="6-10 digits"
              className="input_field"
              value={formData.accountNumber}
              onChange={(e) =>
                setFormData({ ...formData, accountNumber: e.target.value })
              }
            />
          </div>
        </div>
        {country !== "AU" && (
          <div className="form_group">
            <div className="input_group">
              <label htmlFor="accountNumber">IBAN:</label>
              <input
                type="text"
                name="iban"
                placeholder="6-10 digits"
                className="input_field"
                value={formData.iban}
                onChange={(e) =>
                  setFormData({ ...formData, iban: e.target.value })
                }
              />
            </div>
            <div className="input_group">
              <label htmlFor="bsbNumber">BIC/SWIFT:</label>
              <input
                type="text"
                name="swiftCode"
                className="input_field"
                placeholder="6 digits"
                value={formData.swiftCode}
                onChange={(e) =>
                  setFormData({ ...formData, swiftCode: e.target.value })
                }
              />
            </div>
          </div>
        )}
        {error && <p className="error_msg">{error}</p>}
        {isLoading ? (
          <button className="submit_btn" type="submit" disabled>
            <div className="spinner"></div>
          </button>
        ) : (
          <button className="submit_btn" type="submit">
            Update Details
          </button>
        )}
      </form>
    </section>
  );
}
