import React, { useState, useEffect } from "react";
import { TbTriangleFilled, TbTriangleInvertedFilled } from "react-icons/tb";
import {
  formatNumber,
  getAuthUser,
  updateStockInDB,
  getStockFromUserDB,
} from "../../../firebase/firestore";
import axios from "axios";
import "./style.css";

function StockTable() {
  const [stocksData, setStocksData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const user = getAuthUser();

  const getStatusStyles = (status) => {
    switch (status) {
      case "Pending":
        return { backgroundColor: "#ff9400", color: "white" };
      case "Outstanding":
        return { backgroundColor: "red", color: "white" };
      case "Paid":
        return { backgroundColor: "green", color: "white" };
      case "Sold":
        return { backgroundColor: "#007dff", color: "white" };
      default:
        return {};
    }
  };

  const getProfitLossStyles = (percentage) => {
    const isProfit = percentage >= 0;
    const color = isProfit ? "green" : "red";
    const icon = isProfit ? <TbTriangleFilled /> : <TbTriangleInvertedFilled />;
    return { color, icon };
  };

  useEffect(() => {
    const fetchStocksData = async () => {
      setIsLoading(true); // Set loading status to true at the beginning

      if (!user) {
        console.log("No user is currently authenticated.");
        setIsLoading(false); // Set loading status back to false
        return;
      }

      try {
        // Use the getStockFromUserDB function to fetch stock data for the user
        const userStocks = await getStockFromUserDB(user);

        // Update the state with the fetched stock data
        setStocksData(userStocks || []); // Set to an empty array if userStocks is null
      } catch (error) {
        console.error("Error fetching user stocks: ", error);
        // Handle the error (e.g., show an error message to the user)
      } finally {
        setIsLoading(false);
      }
    };

    // Call the fetchStocksData function
    fetchStocksData();
  }, []);

  const stockApiKey = process.env.REACT_APP_STOCK_API_KEY;

  useEffect(() => {
    const fetchAndUpdateStocksData = async () => {
      setIsLoading(true);

      try {
        const userStocks = await getStockFromUserDB(getAuthUser());
        if (userStocks) {
          // Using Promise.all to handle parallel updates
          await Promise.all(userStocks.map((stock) => updateStockData(stock)));
        }
      } catch (error) {
        console.error("Error fetching and updating user stocks: ", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAndUpdateStocksData();
  }, []); // Empty dependency array to run only on component mount

  const updateStockData = async (stock) => {
    const updatedStock = await getStockData(stock.symbol);
    if (updatedStock) {
      // Recalculate dependent fields
      const updatedFields = recalculateDependentFields(
        stock.shares,
        stock.tradePrice,
        updatedStock.marketPrice,
        stock.type
      );

      // Update the stock information with new data
      const updatedStockInfo = {
        ...stock,
        ...updatedFields,
        marketPrice: updatedStock.marketPrice,
      };

      // Update the stock in the database
      await updateStockInDB(user, updatedStockInfo);

      // Update local state
      setStocksData((prevStocksData) =>
        prevStocksData.map((sd) => (sd.id === stock.id ? updatedStockInfo : sd))
      );
    }
  };

  const getStockData = async (symbol) => {
    try {
      const options = {
        method: 'GET',
        url: 'https://real-time-finance-data.p.rapidapi.com/stock-quote',
        params: { symbol: `${symbol}:NASDAQ`, language: 'en' },
        headers: {
          'X-RapidAPI-Key': stockApiKey, // Replace with your actual API Key
          'X-RapidAPI-Host': 'real-time-finance-data.p.rapidapi.com'
        }
      };
  
      const response = await axios.request(options);
      const data = response.data;
  
      if (data.status === "OK") {
        const marketPrice = parseFloat(data.data.price).toFixed(2);
        return { marketPrice };
      } else {
        setError("Error fetching stock details. Please try again.");
        return null;
      }
    } catch (error) {
      console.error("Error fetching stock details:", error.message);
      setError("Error fetching stock details. Please try again.");
      return null;
    }
  };
  

  const recalculateDependentFields = (shares, tradePrice, marketPrice, type, purchasePrice) => {
    const parsedShares = parseFloat(shares) || 0;
    const parsedTradePrice = parseFloat(tradePrice) || 0;
    const parsedMarketPrice = parseFloat(marketPrice) || 0;

    let tradeAmount, marketValue, profitLoss;

    tradeAmount = parsedShares * parsedTradePrice;
    marketValue = parsedShares * parsedMarketPrice;
    purchasePrice = parsedShares * parsedTradePrice; 

    if (type === "Sell") {
      // When selling, calculate profit or loss
      profitLoss = (marketValue - tradeAmount) / tradeAmount * 100;
    } else {
      // When buying, calculate total purchase price
      profitLoss = tradeAmount
      ? ((marketValue - tradeAmount) / tradeAmount) * 100
      : 0;
    } 

    return {
        tradeAmount: tradeAmount.toFixed(2),
        value: marketValue.toFixed(2),
        profitLoss: profitLoss.toFixed(2),
    };
};


  return (
    <div style={{ width: "100%", overflow: "scroll" }}>
      {isLoading ? (
        <div className="stock_spinner"></div>
      ) : (
        <table className="stock_table">
          {stocksData.length > 0 ? (
            <>
              <thead>
                <tr>
                  <th title="Symbol">SYM</th>
                  <th title="Company Name">Company</th>
                  <th title="Type of stock purchased">Type</th>
                  <th title="Number of Shares">Shares</th>
                  <th title="Trade Date">Date</th>
                  <th title="Trade Price">TP</th>
                  <th title="Market Price">MP</th>
                  <th title="Trade Amount">TA</th>
                  <th title="Value">Value</th>
                  <th title="Profit or Loss Percentage">+/- (%)</th>
                  <th title="Status of Purchase">Status</th>
                </tr>
              </thead>
              {stocksData.map((stock, index) => (
                <tbody key={index}>
                  <tr>
                    <td>{stock.symbol}</td>
                    <td>{stock.companyName}</td>
                    <td>{stock.type}</td>
                    <td>{stock.shares}</td>
                    <td>{stock.tradeDate}</td>
                    <td>$ {formatNumber(stock.tradePrice)}</td>
                    <td>$ {formatNumber(stock.marketPrice)}</td>
                    <td>$ {formatNumber(stock.tradeAmount)}</td>
                    <td>$ {formatNumber(stock.value)}</td>
                    <td
                      className="table_percent"
                      style={{
                        color: getProfitLossStyles(stock.profitLoss).color,
                      }}
                    >
                      {getProfitLossStyles(stock.profitLoss).icon}
                      {stock.profitLoss} %
                    </td>
                    <td>
                      <label
                        className="table_status"
                        htmlFor="status"
                        style={getStatusStyles(stock.status)}
                      >
                        {stock.status}
                      </label>
                    </td>
                  </tr>
                </tbody>
              ))}
            </>
          ) : (
            <tbody>
              <tr>
                <td className="no_holding">No available Stocks.</td>
              </tr>
            </tbody>
          )}
        </table>
      )}
    </div>
  );
}

export default StockTable;
