import React, { useState } from "react";
import Sidebar from "../Sidebar";
import { RiMenu3Line } from "react-icons/ri";
import Logo from "../../assets/logo.png";
import { Outlet } from 'react-router-dom';
import "./style.css";

export default function DashboardLayout() {
  const [isSidebarVisible, setSidebarVisibility] = useState(false);
  const toggleSidebar = () => {
    setSidebarVisibility(!isSidebarVisible);
  };
  const closeSidebar = () => {
    setSidebarVisibility(false);
  };

  return (
    <div className="app_container">
      <div className="side_piece">
        <Sidebar closeSidebar={closeSidebar} />
      </div>
      <header className="header">
        <div className="header_left">
          <img src={Logo} alt="Logo" />
        </div>
        <nav className="navbar">
          <div className="nav_icon" onClick={toggleSidebar}>
            <RiMenu3Line size={28} />
          </div>
          <div className={`nav_sidebar ${isSidebarVisible ? 'show' : ''}`}>
            <Sidebar closeSidebar={closeSidebar} />
          </div>
        </nav>
      </header>
      <div className="content_area">
        <Outlet />
      </div>
    </div>
  );
}
