import React, { useEffect, useState } from "react";
import BuyBondsModal from "./Modal/BuyBondsModal";
import { formatNumber, getAuthUser, getBonds } from "../../firebase/firestore";
import { getAuth } from "firebase/auth";
import LoadingScreen from "../LoadingScreen";
import "./style.css";

export default function Bonds() {
  const [bonds, setBonds] = useState([]);
  const [bondsModalOpen, setBondsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [visibleDropdownIndex, setVisibleDropdownIndex] = useState(null);
  const [selectedBond, setSelectedBond] = useState(null);

  const toggleDropdown = (index) => {
    if (visibleDropdownIndex === index) {
      setVisibleDropdownIndex(null); // if clicked again on the open dropdown, close it
    } else {
      setVisibleDropdownIndex(index); // open the clicked dropdown and close any other open dropdown
    }
  };

  useEffect(() => {
    setIsLoading(true); // Set loading status to true at the beginning

    const uid = getAuthUser();

    // Set an observer on the Auth object to get the user's sign-in state.
    if (uid) {
      const fetchBonds = async () => {
        let bonds = await getBonds(uid);
        bonds = bonds.sort((a, b) => a.index - b.index);
  
        if (bonds) {
          setBonds(bonds);
        }
        setIsLoading(false); // Set loading status back to false
      };
      fetchBonds();
    } else {
      setIsLoading(false); // Set loading status back to false
    }

    // Cleanup subscription on unmount
    return () => {
      setIsLoading(false); // Set loading status back to false
    };
  }, []);

  return (
    <div className="bondsPage_Wrapper">
      <div className="headerSection">
        <h2 className="title">Investment Options - Bonds</h2>
        <div className="svgWrapper">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="180"
            height="9"
            viewBox="0 0 230 9"
          >
            <rect
              id="Rectangle_28"
              data-name="Rectangle 28"
              width="230"
              height="9"
              rx="4.5"
              fill="#688fb7"
            ></rect>
          </svg>
        </div>
      </div>
      <div className="contentBody">
        {bonds.length === 0 && <h5>No Bonds Available.</h5>}
        {isLoading ? (
          <LoadingScreen />
        ) : (
          bonds.map((bond) => (
            <div
              key={bond.index}
              className="bondCard"
              onClick={() => toggleDropdown(bond.index)}
            >
              <div className="bondDetails">
                <div className="bondColumn">
                  <div className="issuerImageWrapper">
                    <img src={bond.image} alt="Logo" />
                    
                  </div>
                  <div className="columnDetails">
                    <div className="issuerDetails">
                      <p className="issuerName"> {bond.issuerName} </p>
                      <div className="bondTypeWrapper">
                        <span className="bondType"> {bond.type} </span>
                      </div>
                    </div>
                    <div className="additionalDetails">
                      <div className="details">
                        <p className="boldText">Sector:</p>
                        <span className="regularText"> {bond.sector} </span>
                      </div>
                      <div className="details">
                          <p className="boldText">Maturity Date:</p>
                          <span className="regularText">
                          {bond.maturityDate ? bond.maturityDate : "N/A"}
                          </span>
                        </div>
                        <div className="details">
                          <p className="boldText">Minimum Investment:</p>
                          <span className="regularText">
                            $ {formatNumber(bond.minimumAmount)}
                          </span>
                        </div>
                      <div className="details">
                          <p className="boldText">ISIN:</p>
                          <span className="regularText">{bond.isin}</span>
                        </div>
                        <div className="details">
                          <p className="boldText">Coupon Frequency:</p>
                          <span className="regularText">
                            {bond.couponFrequency}
                          </span>
                      </div>
                    </div>
                  </div>
                  <div className="couponDetails">
                    <div className="couponWrapper">
                      <div className="couponRate">
                        <span className="couponNumber">
                          {" "}
                          {bond.couponRate}{" "}
                        </span>
                        <span className="percentageSymbol">%</span>
                      </div>
                      <span className="regularText">Coupon</span>
                    </div>
                  </div>
                  <div className="purchaseButtonWrapper">
                      <button
                        onClick={() => {
                          setBondsModalOpen(true);
                          setSelectedBond(bond);
                        }}
                        className="purchaseButton"
                      >
                        Buy bond
                      </button>
                    </div>
                </div>
              </div>
            </div>
          ))
        )}
        <BuyBondsModal
          isOpen={bondsModalOpen}
          onClose={() => {
            setBondsModalOpen(false);
            setSelectedBond(null);
          }}
          bond={selectedBond}
        />
      </div>
    </div>
  );
}
