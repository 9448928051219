import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import my_accounts_icon from "../../assets/my_accounts.png";
import transactions_icon from "../../assets/transaction.png";
import stock_icon from "../../assets/stock_market.png";
import bonds_icon from "../../assets/bond.png";
import fixedTerm_icon from "../../assets/deposit.png";
import ipos_icon from "../../assets/ipo.png";
import chat_icon from "../../assets/live_chat.png";
// import calculator_icon from "../../assets/calculator.png";
import accountDetails_icon from "../../assets/account_details.png";
import notification_icon from "../../assets/notification.png";
import logout_icon from "../../assets/logout.png";
import { getAuth } from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { addLogNotification, displayBondsFeature, displayChatFeature, displayFixedTermFeature, displayIpoFeature, displayToolsFeature } from "../../firebase/firestore";
import Logout from "../Modals/logout";
import { db } from "../../firebase/firebase";
import "./style.css";

const DashboardCard = ({ to, icon, label, onClick }) => {
  return (
    <li className="card" onClick={onClick}>
      <Link to={to} className="homePage_link">
        <div className="card_body">
          <img src={icon} className="homePage_icon" alt="card icon" />
          <p className="homePage_label">{label}</p>
        </div>
      </Link>
    </li>
  );
};

function HomePage() {
  const [isLoading, setIsLoading] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [isMarketVisible, setIsMarketVisible] = useState(false);
  const [isBondsVisible, setIsBondsVisible] = useState(false);
  const [isTermsVisible, setIsTermsVisible] = useState(false);
  const [isIposVisible, setIsIposVisible] = useState(false);

  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    // Subscribe to chat feature visibility changes
    const unsubscribe = displayChatFeature(setIsChatVisible);
    const unsubscribeTools = displayToolsFeature(setIsMarketVisible);
    const unsubscribeBonds = displayBondsFeature(setIsBondsVisible);
    const unsubscribeTerms = displayFixedTermFeature(setIsTermsVisible);
    const unsubscribeIpos = displayIpoFeature(setIsIposVisible);
    // Cleanup function
    return () => {
      unsubscribe();
      unsubscribeTools();
      unsubscribeBonds();
      unsubscribeTerms();
      unsubscribeIpos();
    };
  }, []);

  // Logout function
  const handleLogout = async () => {
    setIsLoading(true);

    try {
      const user = auth.currentUser;

      if (user) {
        // Fetch the user's details from Firestore
        const userRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          await updateDoc(userRef, { isLoggedIn: false });
          await addLogNotification(userRef, user);
        }
        await auth.signOut();
      }

      setIsLoading(false);
      navigate("/");
    } catch (error) {
      setIsLoading(false);
      console.error("Error signing out:", error);
    }
  };

  return (
    <div className="homePage">
      <div className="homePage_header">
      </div>
      <ul className="homePage_cards">
        <DashboardCard
          to="/dashboard/my-accounts"
          icon={my_accounts_icon}
          label="My Accounts"
        />
        {/* <DashboardCard
          to="/dashboard/transactions"
          icon={transactions_icon}
          label="Transactions"
        /> */}
        {isBondsVisible && (<DashboardCard
          to="/dashboard/bonds"
          icon={bonds_icon}
          label="Bonds"
        />)}
        {isTermsVisible && (<DashboardCard
          to="/dashboard/fixed-term-deposits"
          icon={fixedTerm_icon}
          label="Fixed Term Deposits"
        />)}
        {isIposVisible && (<DashboardCard
          to="/dashboard/ipos"
          icon={ipos_icon}
          label="IPOS"
        /> )}
        {isMarketVisible && (<DashboardCard
          to="/dashboard/market-analysis"
          icon={stock_icon}
          label="Market Analysis"
        />)}
        <DashboardCard
          to="/dashboard/account-details"
          icon={accountDetails_icon}
          label="Account Details"
        />
       {/* {isChatVisible && ( <DashboardCard
          to="/dashboard/chat"
          icon={chat_icon}
          label="Chat With Us"
        />)} */}
        {/* <DashboardCard
          to="/dashboard/interest-calculator"
          icon={calculator_icon}
          label="Interest Calculator"
        /> */}
        <DashboardCard
          to="/dashboard/notifications"
          icon={notification_icon}
          label="Notifications"
        />
        <DashboardCard
          icon={logout_icon}
          label="Logout"
          onClick={() => setIsLogoutModalOpen(true)}
        />
      </ul>
      <Logout
        isOpen={isLogoutModalOpen}
        isLoading={isLoading}
        onLogout={() => {
          setIsLoading(true);
          handleLogout();
          auth
            .signOut()
            .then(() => {
              setIsLoading(false);
              navigate("/");
            })
            .catch((error) => {
              setIsLoading(false);
              console.error("Error signing out:", error);
            });
        }}
        onClose={() => setIsLogoutModalOpen(false)}
      />
    </div>
  );
}

export default HomePage;
