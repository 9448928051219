import React, { useState } from "react";
import {
  buyBonds,
  formatNumber,
  getAuthUser,
  getCurrentDate,
  getUserName,
} from "../../../firebase/firestore";
import CurrencyInput from "react-currency-input-field";
import "./style.css";
import Swal from "sweetalert2";

export default function BuyBondsModal({ isOpen, onClose, bond }) {
  const [bondsAmount, setBondsAmount] = useState(0);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleBuyBonds = async () => {
    const minimumInvestmentAmount = bond.minimumAmount;
    if (minimumInvestmentAmount > bondsAmount || bondsAmount === 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `Cannot buy less than $${minimumInvestmentAmount}`,
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    }

    const userName = await getUserName();

    // Calculate how many bonds the user is buying
    const amountAsNumber = parseFloat(bondsAmount);
    const numberOfBondsBought = amountAsNumber / minimumInvestmentAmount;

    // Create bond data
    const bondData = {
      amountRequested: amountAsNumber,
      image: bond.image,
      type: bond.type,
      couponRate: bond.couponRate,
      companyWebsite: bond.companyWebsite,
      isin: bond.isin,
      maturityDate: bond.maturityDate,
      purchaseDate: getCurrentDate(),
      currentValue: amountAsNumber,
      issuerName: bond.issuerName,
      sector: bond.sector,
      couponFrequency: bond.couponFrequency,
      minimumAmount: bond.minimumAmount,
      quantity: numberOfBondsBought,
      userName: userName,
    };
    setIsLoading(true);
    const uid = getAuthUser();
    try {
      await buyBonds(uid, bondData);

      setMessage("Request has been sent.");
      setTimeout(() => {
        setMessage("");
        setBondsAmount(0);
        onClose();
      }, 3000);
    } catch (error) {
      setError(`There was an issue sending the request. Try again later.`);
      console.log(error);
      setTimeout(() => {
        setError("");
        setBondsAmount(0);
      }, 4000);
    }

    setIsLoading(false);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal_overlay" onClick={(e) => e.stopPropagation()}>
      <div className="modal">
        <div className="section_header">
          <h2 className="title">
            Purchase of <br />
            {bond.issuerName}
          </h2>
          <div className="subtitle">
            <span>{bond.type}</span>
          </div>
        </div>
        <div className="bondSection_body">
          <div className="more_dets">
            <div className="maturity_row">
              <p className="bold_text">Sector:</p>
              <span className="reg_text">{bond.sector}</span>
            </div>

            <div className="">
              <div className="maturity_row">
                <p className="bold_text">Maturity Date:</p>
                <span className="reg_text">{bond.maturityDate}</span>
              </div>
              <div className="maturity_row">
                <p className="bold_text">Minimum Amount:</p>
                <span className="reg_text">
                  $ {formatNumber(bond.minimumAmount)}
                </span>
              </div>
            </div>
          </div>
          <div className="input_group">
            <label htmlFor="title">Input Amount:</label>
            <CurrencyInput
              decimalSeparator="."
              prefix="$"
              name="bondsAmount"
              placeholder="$0"
              defaultValue={bondsAmount}
              decimalsLimit={2}
              onValueChange={(value) => {
                const formattedValue = parseFloat(value).toFixed(2);
                setBondsAmount(parseFloat(formattedValue)); // Store as a number
              }}
            />
          </div>
        </div>
        {message && <p className="success_msg">{message}</p>}
        {error && <p className="error_msg">{error}</p>}
        <div className="buttons_wrap">
          <button
            onClick={() => {
              handleBuyBonds();
            }}
            className="submit_btn"
          >
            Request
          </button>
          {isLoading && (
            <div className="spinner" style={{ margin: "0 auto" }}></div>
          )}
          <button onClick={onClose} className="cancel_btn">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
